<template>
  <div class="ai__nav-wrapper">
    <button class="close__ai-nav--btn" id="ai__nav-close--link">
      <svg data-name="Capa 1" id="Capa_1" viewBox="0 0 20 19.84" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.17,10l3.89-3.89a.37.37,0,1,0-.53-.53L9.64,9.43,5.75,5.54a.37.37,0,1,0-.53.53L9.11,10,5.22,13.85a.37.37,0,0,0,0,.53.34.34,0,0,0,.26.11.36.36,0,0,0,.27-.11l3.89-3.89,3.89,3.89a.34.34,0,0,0,.26.11.35.35,0,0,0,.27-.11.37.37,0,0,0,0-.53Z" />
      </svg>
    </button>
    <div class="ai__assistant-title">
      <h2>AI Assistant</h2>
      <p>Enhance your store management with personalized assistance and quick actions.</p>
    </div>
    <div class="suggestions__list">
      <h3 @click="toggleQuickActions" style="cursor: pointer; display: flex; align-items: center;">
        Quick Actions
        <!-- <svg :class="{ 'rotate': showQuickActions }" style="margin-left: 8px; transition: transform 0.3s;" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 16.5l-8-8h16z"/>
        </svg> -->
        <svg :class="{ 'rotate': showQuickActions }" style="margin-left: 8px; transition: transform 0.3s;" width="14" height="14" viewBox="0 0 139 86" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M122.61 -6.18539e-06L69.5 53.1773L16.3904 -1.5424e-06L-3.04182e-06 16.4113L69.5 86L139 16.4113L122.61 -6.18539e-06Z" fill="#616161"/>
        </svg>
      </h3>
      <transition name="fade">
        <ul v-if="showQuickActions">
          <li>
            <button @click="setMessageAndSend('Take me to the Add New Product page.')">
              Take me to the Add New Product page.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Show me my latest orders.')">
              Show me my latest orders.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Navigate to the Blog screen.')">
              Navigate to the Blog screen.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Open the Theme Customization section.')">
              Open the Theme Customization section.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Direct me to the Coupon setup.')">
              Direct me to the Coupons setup.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Bring up Customer screen.')">
              Bring up Customer screen.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Go to Shipping Regions.')">
              Go to Shipping Region.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Access Payment Methods.')">
              Access Payment Methods.
            </button>
          </li>
          <li>
            <button @click="setMessageAndSend('Take me to Store Settings.')">
              Take me to Store Settings.
            </button>
          </li>
          <hr>
        </ul>
      </transition>
      <ul class="conversation__list">
        <li v-for="(msg, index) in conversation" :key="index"
          :class="{ 'user-message': msg.role === 'user', 'assistant-message': msg.role === 'assistant' }">
          <p v-if="msg.role === 'user'">{{ msg.message }}</p>
          <p v-else>
            <span v-if="msg.invalid">{{ msg.message }}</span>
            <template v-else>
              <router-link :to="msg.message">{{ msg.name }}<br />{{ msg.message }}</router-link>
            </template>
          </p>
        </li>
        <li v-if="pending" class="loading-message assistant-message">
          <div class="dot-wave">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="chat__field-block">
      <!--
      <button v-if="isRecording" class="voice__icon-btn" @click="stopRecording">
        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
          <rect fill="none" height="256" width="256" />
          <path
            d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm40,120a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8Z" />
        </svg>
      </button>
      <button v-else class="voice__icon-btn" @click="startRecording">
        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
          <rect fill="none" height="256" width="256" />
          <path d="M128,176a48,48,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48,48,0,0,0,128,176Z" />
          <path
            d="M200.4,128.1a8,8,0,0,0-8.8,7,64,64,0,0,1-127.2,0,8,8,0,0,0-8.8-7,7.9,7.9,0,0,0-7.1,8.8A79.7,79.7,0,0,0,120,207.3V232a8,8,0,0,0,16,0V207.3a79.7,79.7,0,0,0,71.5-70.4A7.9,7.9,0,0,0,200.4,128.1Z" />
        </svg>
      </button>
      -->
      <form @submit.prevent="sendQuery">
        <div class="chat__field">
          <textarea v-model="message" placeholder="Write your prompt, i.e., 'Please open the Add New Product page.'"
            @keydown.enter="handleEnter"></textarea>
          <!-- <input type="text" placeholder="i.e., Open the Add Product page."> -->
          <button type="submit">
            <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="info" />
              <g id="icons">
                <path
                  d="M21.5,11.1l-17.9-9C2.7,1.7,1.7,2.5,2.1,3.4l2.5,6.7L16,12L4.6,13.9l-2.5,6.7c-0.3,0.9,0.6,1.7,1.5,1.2l17.9-9   C22.2,12.5,22.2,11.5,21.5,11.1z"
                  id="send" />
              </g>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Search',
  data() {
    return {
      // conversation: [],
      conversation: [],
      message: "",
      showQuickActions: true,
      pending: false,
      isRecording: false,
    };
  },
  methods: {
    toggleQuickActions() {
      this.showQuickActions = !this.showQuickActions;
    },
    async sendQuery() {
      this.showQuickActions = false;
      if (this.pending) {
        this.$notify.error("Please wait for the previous query to complete.");
        return;
      }
      if (this.message.trim() === "") {
        this.$notify.error("Write your prompt, i.e., 'Please open the Add New Product page.");
        return;
      }
      this.conversation.push({
        id: this.uuidv4(),
        message: this.message,
        role: "user"
      });
      this.pending = true;
      try {
        const response = await this.$axios.post('/ai/search', {
          query: this.message
        });
        this.message = "";
        let obj = {
          id: this.uuidv4(),
          message: response.data.data,
          role: "assistant"
        };
        if (response.data.data === "/") {
          obj.message = "Sorry, I didn't get that. Please try again with a different prompt.";
          obj.invalid = true;
          obj.retry = true;
        } else {
          let module = this.getModuleBySlug(response.data.data);
          if (module) {
            obj.name = module.name;
          }else{
            obj.message = "Sorry, I didn't get that. Please try again with a different prompt.";
            obj.invalid = true;
            obj.retry = true;
          }
        }

        this.conversation.push(obj);

        // console.log(response.data);
      } catch (error) {
        let message = error.response.data.message || error.message;
        // this.$notify.error(message);
        this.$message({
          type: 'error',
          showClose: true,
          message: message,
        });
      } finally {
        this.pending = false;
      }
    },
    handleEnter(event) {
      // console.log(event);
      if (!event.shiftKey) {
        event.preventDefault();
        this.sendQuery();
      }
    },
    setMessageAndSend(message) {
      this.message = message;
      this.sendQuery();
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getModuleBySlug(slug) {
      let modules = [
        {
          "id": "0cc122db-d696-46a3-9719-4cb97b9fec50",
          "name": "Customers",
          "slug": "/e-commerce/customer",
          "keywords": "[\"Customers\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2024-12-09 07:36:21"
        },
        {
          "id": "0e8fa9dd-8570-4a09-b3bc-ab1486195fec",
          "name": "Gallery",
          "slug": "/content-management/gallery",
          "keywords": "[\"Gallery\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "0fa2b53f-a204-41aa-a065-19307fc50aa7",
          "name": "Coupon & Discounts",
          "slug": "/marketing/coupon",
          "keywords": "[\"Coupon & Discounts\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:28:50"
        },
        {
          "id": "2a06adc5-7213-410f-bce1-43b12efb0c0f",
          "name": "Banking/Accounts",
          "slug": "/expenses-purchases/account",
          "keywords": "[\"Banking\", \"Accounts\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-02-27 09:27:51"
        },
        {
          "id": "3193135f-0ee1-4a68-aede-bb2d413fcf37",
          "name": "Shipping Regions",
          "slug": "/e-commerce/shipping-region",
          "keywords": "[\"Shipping Regions\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-12-09 07:35:36"
        },
        {
          "id": "41ef42bf-9f39-4c42-b554-de0038adf8d0",
          "name": "SEO (General)",
          "slug": "/seo/general",
          "keywords": "[\"SEO (General)\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-12-09 07:36:14"
        },
        {
          "id": "4c87796f-7738-4aa0-9d66-2cd15c3cda94",
          "name": "Expenses",
          "slug": "/expenses-purchases/expense",
          "keywords": "[\"Expenses\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-02-27 09:27:38"
        },
        {
          "id": "4db36c3a-0722-4568-934b-99d03882ba8b",
          "name": "Blog Posts",
          "slug": "/content-management/blog",
          "keywords": "[\"Blogs\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:29:51"
        },
        {
          "id": "57dbc5d6-f7c6-41e2-819a-917e2b3a87e1",
          "name": "Subscriptions",
          "slug": "/subscription",
          "keywords": "[\"Subscriptions\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "5b51464c-ce3f-469a-8cfc-afb1e531ae77",
          "name": "Theme Banners",
          "slug": "/theme-management/theme-banner",
          "keywords": "[\"Home Banner\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:29:46"
        },
        {
          "id": "67d760bf-f42f-49fc-b214-40e647ac981c",
          "name": "Terms & Conditions",
          "slug": "/settings/terms-and-conditions",
          "keywords": "[\"Terms & Conditions\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "6a7bda74-98b5-43cf-91ed-f872afa7c86e",
          "name": "Blog Categories",
          "slug": "/content-management/blog-category",
          "keywords": "[\"Blog Categories\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "6d84b1d7-7906-4c2b-ac76-eb24ca0e4509",
          "name": "Footer Navigation",
          "slug": "/navigation/footer-navigation",
          "keywords": "[\"Footer Navigation\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2023-06-14 12:34:28"
        },
        {
          "id": "70c8d911-09de-4f17-a2d9-c387a6ca2e82",
          "name": "Dashboard",
          "slug": "/",
          "keywords": "[\"Dashboard\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2023-06-14 12:34:28"
        },
        {
          "id": "7761e69c-a403-4e42-97e5-10ebfc1e46de",
          "name": "Privacy Policy",
          "slug": "/settings/privacy-policy",
          "keywords": "[\"Privacy Policy\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "7ed249b6-d127-4fc9-b621-6d664f85494f",
          "name": "User Management",
          "slug": "/user-management",
          "keywords": "[\"User Management\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2023-06-14 12:34:30"
        },
        {
          "id": "8388fd24-bdcf-4624-9e67-965d1b1a4935",
          "name": "Notifications",
          "slug": "/notification",
          "keywords": "[\"Notifications\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-03-05 09:27:52"
        },
        {
          "id": "84c95d27-af0f-4c2e-b49c-37d07e216f3d",
          "name": "Support Members",
          "slug": "/support-member",
          "keywords": "[\"Support Members\"]",
          "created_at": "2024-03-07 06:16:02",
          "updated_at": "2024-03-07 06:16:02"
        },
        {
          "id": "8b7885f4-c3df-413c-b2e1-6779fe775be9",
          "name": "Robots",
          "slug": "/seo/robots",
          "keywords": "[\"Robots\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "8cf0a28b-b959-44bd-9f76-a69182018b70",
          "name": "Themes",
          "slug": "/theme-management/theme",
          "keywords": "[\"Themes\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-12-09 07:37:26"
        },
        {
          "id": "8f4b2ebd-330f-4544-9791-b858710da7f3",
          "name": "FAQs",
          "slug": "/content-management/faq",
          "keywords": "[\"FAQs\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:28:14"
        },
        {
          "id": "90892d21-7a19-4f68-9ad9-3b8e354e783c",
          "name": "Social Media",
          "slug": "/marketing/social-media",
          "keywords": "[\"Social Media\"]",
          "created_at": "2024-09-03 13:44:45",
          "updated_at": "2024-09-03 13:44:45"
        },
        {
          "id": "9205244e-eb65-4d13-8e25-a7bf0911857d",
          "name": "Domain Setup",
          "slug": "/settings/domain-setup",
          "keywords": "[\"Domain Setup\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "937a4a31-fce7-40db-b507-86e20b2076e6",
          "name": "Theme Customization",
          "slug": "/theme-management/theme-customization",
          "keywords": "[\"Content Blocks\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-10-04 07:58:11"
        },
        {
          "id": "9cf27a02-02e7-422f-9dba-6a7acf87e692",
          "name": "Categories",
          "slug": "/e-commerce/category",
          "keywords": "[\"Categories\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2024-12-09 07:36:45"
        },
        {
          "id": "a1aec300-3b98-4327-99d5-6f8b6abc6891",
          "name": "Search Appearance",
          "slug": "/seo/search-appearance",
          "keywords": "[\"No Index Urls\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "af19955d-45c9-4c29-aa08-c2a23249328c",
          "name": "Page Details",
          "slug": "/seo/page-detail",
          "keywords": "[\"Page Details\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:27:56"
        },
        {
          "id": "b4e24a28-d245-42f9-8d9c-04cab51b8d3d",
          "name": "Bills",
          "slug": "/expenses-purchases/bill",
          "keywords": "[\"Bills\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-02-27 09:27:26"
        },
        {
          "id": "b60d73cf-8752-4664-a490-e35e65cbe392",
          "name": "Header Navigation",
          "slug": "/navigation/header-navigation",
          "keywords": "[\"Header Navigation\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2023-06-14 12:34:28"
        },
        {
          "id": "c11317b3-a4d6-4e31-b912-c246a003fa57",
          "name": "Orders",
          "slug": "/e-commerce/order",
          "keywords": "[\"Order\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2024-12-09 07:36:38"
        },
        {
          "id": "c7383f5d-fe9c-47b2-b932-39944b837008",
          "name": "Pixel Management",
          "slug": "/marketing/pixel-management",
          "keywords": "[\"Pixel Management\"]",
          "created_at": "2024-09-03 13:44:10",
          "updated_at": "2024-09-03 13:44:10"
        },
        {
          "id": "cdf001e0-18dc-468c-90bb-69d4a6de0162",
          "name": "Google Shop",
          "slug": "/marketing/google-shop",
          "keywords": "[\"Google Shop\", \"Merchant Center\"]",
          "created_at": "2024-09-05 09:03:12",
          "updated_at": "2024-11-26 11:19:52"
        },
        {
          "id": "d25d4d5b-abb6-482a-826f-3cba01ee43b5",
          "name": "Vendors",
          "slug": "/expenses-purchases/vendor",
          "keywords": "[\"Vendors\"]",
          "created_at": "2023-06-14 12:34:30",
          "updated_at": "2024-02-27 09:27:45"
        },
        {
          "id": "dee80184-85aa-4d0e-a97a-a7c7dd1f80cb",
          "name": "Products",
          "slug": "/e-commerce/product",
          "keywords": "[\"Products\"]",
          "created_at": "2023-06-14 12:34:28",
          "updated_at": "2024-12-09 07:36:30"
        },
        {
          "id": "e5714d7f-9f94-43e5-b90e-a40594a468f5",
          "name": "Redirects",
          "slug": "/seo/redirect",
          "keywords": "[\"Redirects\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-02-27 09:28:25"
        },
        {
          "id": "e8b9e17a-16d4-47f7-9553-8d25423b81b9",
          "name": "Store Settings",
          "slug": "/settings/store-settings",
          "keywords": "[\"settings\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2023-06-14 12:34:29"
        },
        {
          "id": "ec56694b-118b-4089-8758-19ebc441f500",
          "name": "Dropshipping",
          "slug": "/marketing/dropshipping",
          "keywords": "[\"Dropshipping\"]",
          "created_at": "2024-09-03 13:43:29",
          "updated_at": "2024-09-03 13:43:29"
        },
        {
          "id": "fecd7d25-5439-4551-94a5-7649b4deec69",
          "name": "Payment Methods",
          "slug": "/e-commerce/payment-method",
          "keywords": "[\"Payment Methods\"]",
          "created_at": "2023-06-14 12:34:29",
          "updated_at": "2024-12-09 07:34:47"
        }
      ]
      return modules.find(module => module.slug === slug);
    },
    async startRecording() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        //Feature is not supported in browser
        this.$alert('mediaDevices API or getUserMedia method is not supported in this browser.', 'Recording Error', {
          confirmButtonText: 'Ok'
        })
        return;
      }
      this.isRecording = true;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks = [];

        mediaRecorder.ondataavailable = event => {
          audioChunks.push(event.data);
        };

        mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          this.file = audioBlob;
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
          // You can also send the audioBlob to your server here
          try {
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');
            const response = await this.$axios.post('/upload/audio', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            // console.log('Audio uploaded successfully', response.data);
          } catch (error) {
            console.error('Error uploading audio', error);
            this.$notify.error('Error uploading audio');
          }
        };

        mediaRecorder.start();
        this.mediaRecorder = mediaRecorder;
      } catch (error) {
        if (error.message == 'Permission denied') {
          this.$alert('Please grant us permission to access the microphone so we can begin recording.', 'Microphone Permission denied', {
            confirmButtonText: 'Ok'
          });
        } else {
          this.$alert(error, 'Error', {
            confirmButtonText: 'Ok'
          });
        }
      } finally {
        this.isRecording = false;
      }
    },
    stopRecording() {
      this.isRecording = false;
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
    }
    // Your component methods here
  },
  computed: {
    // Your computed properties here
  },
  created() {
    // Lifecycle hook
  },
  mounted() {
    if (this.conversation.length > 0) {
      this.showQuickActions = false;
    }
    // Lifecycle hook
  }
};
</script>

<style scoped>
.rotate {
  transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.user-message {
  background-color: #15213c;
  color: #ffffff;
  padding: 5px 10px;
  display: inline-flex;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  border: 1px solid transparent;
  transition: all 0.3sease-in-out;
  margin-bottom: 5px;
  flex-direction: column;
  justify-content: flex-end;
}

.assistant-message {
  background-color: #f1f1f1;
  color: #333;
  padding: 5px 10px;
  display: flex;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  border: 1px solid transparent;
  transition: all 0.3sease-in-out;
  margin-bottom: 5px;
}

.suggestions__list ul {
  list-style-type: none;
  padding: 0;
}

.suggestions__list li {
  margin: 5px 0;
}

.retry-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  color: #00796b;
}

.dot-wave {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-wave .dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #333;
  border-radius: 50%;
  animation: wave 1.2s infinite ease-in-out;
}

.dot-wave .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot-wave .dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes wave {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
</style>